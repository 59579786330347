import React from 'react';
import { Box, Text, Stack, _hover, Flex } from "@chakra-ui/core";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaGithub,
  FaEnvelope,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaInstagram,
} from "react-icons/fa";

function Footer(props) {
	return <footer className="footer-distributed">
      <span className="seperator"></span>

      <Box className="footer-left">
        <Box>
          <h2>Services</h2>
          <span className="seperator"></span>
        </Box>

        <p className="footer-links">
          
		    <a href="/">Care at Home</a>
          <br />
			  <a href="/transactional-care">Transactional Care</a>
          <br />
          <a href="/physiotherapy">Physiotherapy</a>
          <br />

          <a href="/doctor-e-consultation">Doctor-E-Consulation</a>
          <br />

          <a href="/health-check-up-on-site">Health Checkup OnSite</a>
          <br />

          <a href="/medical-equipments">Medical Equipments</a>
          <br />

          <a href="/vaccination">Vaccination</a>
          <br />

          <a href="/nurses-for-occupational-health-centres">Nurses For OHC And Health Centre</a><br />
          <a href="/pre-policy-health-check-up">Pre policy Health CheckUp</a>
          
        </p>

        <p className="footer-company-name">
          Square Medical and Health Care Services &copy; 2020
        </p>
      </Box>

      <Box className="footer-center ">
        <Box>
          <h2>Address</h2>
          <span className="seperator"></span>

          <Box className="address">
            <FaMapMarkerAlt color="white" />
            <p ml={2}>
              Jewel Home Apartment, G3
              <br />
              Near Delhi International School,
              <br />
              Nobel Park Enclave, North NCL
              <br />
              Kompally, Hyderabad – 500014 Telangana, India
            </p>
          </Box>
        </Box>

        <Box className="contact">
          <Box>
            <FaPhoneAlt color="white" />
          </Box>

          <Box className="mt-3">
            <Stack>
              <a href="tel:+919963434353">(+91) 9963434353</a>

              <a href="tel:+917729837756">(+91) 7729837756</a>
            </Stack>
          </Box>
        </Box>

        <Box className="envelop">
          <p>
            <FaEnvelope />
            <a href="mailto:info@squaremhcs.in" target="_blank">
              info@squaremhcs.in
            </a>
          </p>
        </Box>
      </Box>

      <Box className="footer-right">
        <h6>About The Company</h6>
        <span className="seperator"></span>

        <p className="footer-company-about">
          <b>Square Medical and Health Care Services</b> run by Ex-SERVICEMEN
          who served in Indian Armed Forces for 20+ years in Fields.
        </p>

        <Box className="footer-icons">
          <a href="https://www.facebook.com" target="_blank">
            <FaFacebookF />
          </a>
          <a href="https://www.twitter.com" target="_blank">
            <FaTwitter />
          </a>
          <a href="https://www.linkedin.com" target="_blank">
            <FaLinkedinIn />
          </a>
          <a href="https://www.instagram.com" target="_blank">
            <FaInstagram />
          </a>
        </Box>
      </Box>
    </footer>
}


export default Footer