		import PropTypes from 'prop-types';
import React from 'react';
import {
    Flex,
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    PseudoBox,
    Icon,
    Link,
} from '@chakra-ui/core';

import Logo from './logo';
import PageWrapper from './page.wrapper';



const careAtHome = [
    'Routine Bed Side Care',
    'Elderly Care',
    'Advance Nursing Care',
    'Baby Care',
    'Post-Operative Care',
	'Transactional Care'
];

const menu = [
    'Physiotherapy',
    'Doctor E-Consultation',
    'Health Check-up on site',
    'Medical Equipments',
    'Vaccination',
];

const otherServices = ['Nurses for OHC and Health Centre', 'Pre policy Health check up'];

const Header = ({ siteTitle }) => {
	const navigateTo = (url) => window.location.href = "/" + url;
    return (
        <React.Fragment>
            <Box px={4} py={2} pb={8} bg="white" className="header-main">
                <PageWrapper as={Flex} alignItems="center" justifyContent="space-between" className="header-row-logo">
                    {/* <Box>
          <Menu>
            <MenuButton as={Button} rightIcon="chevron-down">Care@Home</MenuButton>
            <MenuList>
              {careAtHome.map(care => <MenuItem key={care}>{care}</MenuItem>)}
            </MenuList>
          </Menu>
        </Box> */}
                    <Box w="560px" className="header-logo">
						
						<a href="/" > <Logo /></a>
                    </Box>
					<Box color="black" className="homepage">
					 <a href="/" >Home</a>
					</Box>
                    <a
                        _hover={{ color: 'red' }}
                        href="mailto:info@squaremhcs.in"
                        className="header-mail"
                    >
                        <Icon name="email" mr="2" color="#e53e3e" />
                        info@squaremhcs.in
                    </a>
                </PageWrapper>
            </Box>

            <PageWrapper
                as={Flex}
                alignItems="center"
                justifyContent="space-around"
                shadow="md"
                p="4"
                bg="red.500"
                borderRadius={8}
                style={{ transform: 'translateY(-50%)', zIndex: 999, position: 'relative' }}
				className="header-nav"
            >
                <Menu>
					
                    <MenuButton color="white" href="#" rightIcon="chevron-down">
                        Care @ Home
                    </MenuButton>
                    <MenuList>
                        {careAtHome.map((care) => (
                            <Link
                                as={MenuItem}
                                onClick={() => navigateTo(care.toLowerCase().split(' ').join('-'))}
                                href={care.toLowerCase().split(' ').join('-')}
                                key={care}
                            >
                                {care}
                            </Link>
                        ))}
                    </MenuList>
                </Menu>
                {menu.map((link) => (
                    <Link href={'/' + link.toLowerCase().split(' ').join('-')} color="white" key={link}>
                        {link}
                    </Link>
                ))}
                <Menu>
                    <MenuButton color="white" href="#" rightIcon="chevron-down">
                        Other Services
                    </MenuButton>
                    <MenuList>
                        {otherServices.map((care) => (
                            <Link
                                onClick={() => navigateTo(care.toLowerCase().split(' ').join('-'))}
                                as={MenuItem}
                                href={'/' + care.toLowerCase().split(' ').join('-')}
                                key={care}
                            >
                                {care}	
                            </Link>
                        ))}
                        {/* <MenuItem as="a" href="#">Attend a Workshop</MenuItem> */}
                    </MenuList>
                </Menu>
            </PageWrapper>
        </React.Fragment>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;